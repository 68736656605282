/* body {
    background: #ff5f6d;
    background: -webkit-linear-gradient(to right, #00001a, #00004d);
    background: linear-gradient(to right, #00001a, #00004d);
    min-height: 100vh;
    font-family: 'Sanchez', serif;
  }
   */
  #button-addon1 {
    color: #ffc371;
  }
  
  i {
    color: #0000cc;
    font-size: 20px;}
  
  .form-control::placeholder {
    font-size: 0.95rem;
    color: #aaa;
    font-style: italic;
  }
  
  .form-control.shadow-0:focus {
    box-shadow: none;
  }
  
   
  .display-3{
    font-family: 'Sanchez', serif;
  }
  
  p{
    font-family: 'Tajawal', sans-serif;
  }
  
  .lead{
    font-family: 'Sanchez 200', serif;
  }
  
 footer{
    
    border-top: 1px solid #748DA6;
 }
 .text-muted{
    text-decoration: none;
 }
 .text-muted:hover{
    text-decoration: underline;
    font-weight: bold;
 }
 .ccomp{
  text-decoration: none;
  color:white;
  text-align: justify;
 }
 .img2{
  padding-bottom: 5px;
  height:10px;
  width:0px;
  color-scheme: white;
 }
 .color1-br{
  color: white;
  
  text-decoration: none;
}