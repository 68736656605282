.C1clas {
    color: #000;
    overflow-x: hidden;
    height: 100%;
    background: linear-gradient(45deg, #fa9522 50%, #EEEEEE 50%);
    background-repeat: no-repeat;
}

.card0 {
    box-shadow: 0px 4px 8px 0px #757575;
    border-radius: 10px;
}

.card1 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.logo {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 20px;
    margin-left: 25px;
}

.image {
    width: 300px;
    height: 300px;
}

.card2 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.login {
    cursor: pointer;
}

.fa-google {
    background-color: #fff;
    color: #df4b37;
    padding: 10px 12px;
    font-size: 20px;
}

.fa-facebook {
    background-color: #fff;
    color: #3b5998;
    padding: 10px 15px;
    font-size: 20px;
}
.col-lg{
    border-right: 1px solid grey;
}

.google {
    background-color: #df4b37;
    color: #fff;
    padding: 3px 0px 3px 3px;
    border-radius: 2px;
    cursor: pointer;
}

.fb {
    background-color: #3b5998;
    color: #fff;
    padding: 3px 0px 3px 3px;
    border-radius: 2px;
    cursor: pointer;
}

.line {
    height: 1px;
    width: 45%;
    background-color: #E0E0E0;
    margin-top: 10px;
}

.or {
    width: 10%;
}

.text-sm {
    font-size: 14px !important;
}

input, textarea {
    padding: 10px 12px 10px 12px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: #ECEFF1;
}

input:focus, textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #304FFE;
    outline-width: 0;
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0;
}

.btn-blue {
    background-color: #304FFE;
    width: 100%;
    color: #fff;
    border-radius: 6px;
}

.btn-blue:hover {
    background-color: #0D47A1;
    cursor: pointer; 
}
.image{
    height: 350px;
    width: 700px;
    padding-top: 55px;
}
@media screen and (max-width: 991px) {
    .card1 {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
    }

    .card2 {
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px;
    }
    .cmptxt{
        color:black;
        font-size:large;
      }

    
    }