.img1{
    width: 100%;
}
.container2 {
    height: 550px;
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: cover;
    /* display: grid; */
    /* place-items: center; */
}

.header {
    font-size: 80px;
    color: white;
    text-align: center;
}

  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    color: aliceblue;
  }
  .carouselm{
    height: 500px;
  }
  h3{
    color: black;
  }