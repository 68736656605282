.item{
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 20px;
   
}
.contc-1{
    width: 100%;
    background-color: antiquewhite;
}
/* Basic styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* :root{
    --bg-color: #D8D8D8;
} */

.contc-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 200px;
  color: #000;
  background-color: var(--bg-color);
  font-family: 'Maitree', serif;
}

h1{
    font-size: 3em;
    font-weight: normal;
}

/* title styles */
.home-title span{
    position: relative;
    overflow: hidden;
    display: block;
    line-height: 1.2;
}
.spnct{
    border-left: 5px solid rgb(207, 45, 4);
    margin-left: 5px;
    margin-right: 5px;
}
.imgcrd{
    height: 200px;
}