.color-nav{
    background-color: #F9F9F9;
    color: aliceblue;
    height:90px;
    
}
.color-br{
    color: black;
    
    text-decoration: none;
}
/* .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    text-decoration: none;
} */
.nav-item:hover .dropdown-menu{
    display:block!important
  }
  .img1{
    height:50px;
    width:0px;
  }
  .navtxt{
    font-weight:bolder;
    color:gray;
    font-size: 20px;

  }
  .color{
    max-width:10%;
    height:auto;
    background-size: 20px;
    text-decoration: none;
}
.caption{
  padding-inline-start: 2px;
  color:aqua;
  
}