/* Container holding the image and the text */
.container4 {
    position: relative;
    text-align: center;
    color: black;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.253);
  }
.img2 {
    height: 400px;
   
    width: 100%;
    /* display: grid; */
    /* place-items: center; */
}
  
 
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.692);
    height: 100%;
    width: 100%;
  }
  .center2{
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }
  .spnt3{
    border-left: 5px solid rgb(207, 45, 4);
    margin-left: 300px;
    margin-right: 5px;
    text-align: left;
}
