
.cct1{
    display: flex;
    flex: row;
    flex-wrap: wrap;
    padding: 2%;
    background-color: white;
    width: 900px;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border: 1px sold lightgray;
}
.cct11{
    width: 400px;
    border-right: 1px solid grey;
    padding-right: 0.5px;
}
.cct12{
    width: 450px;
    padding: 4%;
    
}

.cct2{
    display: flex;
    flex: row;
    flex-wrap: wrap;
   
}
.cct22{
    width: 700px;
    padding-left: 2%;
    
}
.cct21{
    width: 500px;
    
}

.csub:hover{
    background-color: white;
    zoom: 110%;
    touch-action: pan-x;
}
.cctitle{
    text-align: center;
    font-family: 'Aboreto';
    font-size: 22px;
}

