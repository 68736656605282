.act1{
    display: flex;
    flex: row;
    flex-wrap: wrap;
    padding-left:400px;
}
.act11{
    width: 500px;
    padding-top: 0px ;
    text-align: justify;
    padding-left: 5px;
}
.act12{
    width: 500px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 5%;
}

.act2{
    display: flex;
    flex: row;
    flex-wrap: wrap;
   
}
.act22{
    width: 700px;
    padding-left: 0%;
    align-content: center;

    padding: 0;
}
.act21{
    width: 500px;
    padding-left: 15%;
   
    
}
.act13{
    width: 500px;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-left: 0%;
}
.act24{
    width: 750px;
    padding-left: 0%;
    align-content: center;

    padding: 0;
}
.act4{
    display: flex;
    flex: row;
    flex-wrap: wrap;
    padding-left:50px;
}
.act25{
    width: 700px;
    padding-left: 10%;
    align-content: center;

    padding: 0;
}
.act3{
    display: flex;
    flex: row;
    flex-wrap: wrap;
    padding-left:300px;
}
.act26{
    width: 700px;
    padding-left: 500px;
    align-content: center;

    padding: 1;
}
.act17{
    width: 700px;
    padding-top: 0px ;
    text-align: justify;
    padding-left: 5px;
}